import { useCallback, useEffect, useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { type HttpError } from '@atlassian/teams-client';

import { LegionError } from '../../utils/legion';
import { fireOperationalEvent } from '../analytics';
import { useLazyService } from '../use-service';

import { useTeamsPermissionsStore } from './teams-permission-store';
import {
	type TeamsPermissionsResponse,
	type TeamsPermissionsService,
} from './teams-permission-store/types';
import { requestPermissions } from './utils';

const useTeamsPermissionsServiceLegacy: TeamsPermissionsService = (
	scope,
	{ enabled, initialState, attributes } = { enabled: false, initialState: {}, attributes: {} },
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// backwards compatibility allow orgId to be passed as a string
	const { orgId, cloudId } =
		typeof scope === 'string' || !scope ? { orgId: scope, cloudId: undefined } : scope;

	const [state, { setError, setLoading, setPermissions }] = useTeamsPermissionsStore();

	const [request, controller] = useMemo(
		() =>
			requestPermissions({
				orgId,
				apiVersion: 'v4',
				siteId: cloudId || 'None',
			}),
		[orgId, cloudId],
	);

	const [getPermissions, { loading, error, data }] = useLazyService<
		TeamsPermissionsResponse,
		LegionError | HttpError | Error
	>(request, controller, { ErrorHandler: LegionError, initialState });

	const isLoadingAnywhere = state.isLoading || loading;
	const hasLoadedAnywhere = state.error || state.hasLoaded || !!data || !!error;
	const willSendRequest = orgId && enabled && !hasLoadedAnywhere && !isLoadingAnywhere;

	const analyticAttributes = useMemo(
		() => ({
			userId: attributes?.userId,
			orgId,
			cloudId,
		}),
		[attributes?.userId, orgId, cloudId],
	);

	useEffect(() => {
		const fetchData = async () => {
			if (willSendRequest) {
				setLoading(true);
				fireOperationalEvent(createAnalyticsEvent, {
					action: 'fetched',
					actionSubject: 'use-team-permissions',
					attributes: analyticAttributes,
				});
				await getPermissions();
			}
		};

		fetchData();

		return () => {
			setLoading(false);
		};
	}, [
		getPermissions,
		setLoading,
		willSendRequest,
		createAnalyticsEvent,
		orgId,
		cloudId,
		analyticAttributes,
	]);

	useEffect(() => {
		if (data?.permissions) {
			setPermissions(data.permissions);
		}

		if (error) {
			setError(error);
		}
	}, [data, error, setPermissions, setError]);

	return {
		loading,
		data,
		...state,
	};
};

export const useTeamsPermissionsServiceNext: TeamsPermissionsService = (
	scope,
	{ enabled, attributes } = { enabled: false, attributes: {} },
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// backwards compatibility allow orgId to be passed as a string
	const { orgId, cloudId } =
		typeof scope === 'string' || !scope ? { orgId: scope, cloudId: undefined } : scope;

	const [state, { getPermissions }] = useTeamsPermissionsStore();

	const fetchData = useCallback(async () => {
		getPermissions(
			{ orgId, cloudId, enabled },
			{
				onFetch: () =>
					fireOperationalEvent(createAnalyticsEvent, {
						action: 'fetched',
						actionSubject: 'use-team-permissions',
						attributes: {
							userId: attributes?.userId,
							orgId,
							cloudId,
						},
					}),
			},
		);
	}, [orgId, cloudId, enabled, getPermissions, createAnalyticsEvent, attributes?.userId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return {
		loading: state.isLoading,
		data: state.permissionsResponse,
		error: state.error,
		...state,
	};
};

export const useTeamsPermissionsService: TeamsPermissionsService = fg(
	'pf-directory-settings-query-migration',
)
	? useTeamsPermissionsServiceNext
	: useTeamsPermissionsServiceLegacy;
