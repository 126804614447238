import { AbortControllerFacade } from '../abort-controller';
import { requestWithAbort, type ServiceRequestWithAbort } from '../use-service';

export type RequestPermissionsUrlParams =
	| { apiVersion: 'v3'; siteId?: never }
	| { apiVersion: 'v4'; siteId: string };

export const urlGetPermissions = ({
	orgId,
	siteId,
	apiVersion,
}: RequestPermissionsUrlParams & { orgId: string }): string =>
	`/gateway/api/${apiVersion}/teams/permission/self/organization/${orgId}${
		apiVersion === 'v4' ? `?siteId=${siteId}` : ''
	}`;

export const requestPermissions = ({
	orgId,
	...props
}: RequestPermissionsUrlParams & {
	orgId?: string;
}): ReturnType<ServiceRequestWithAbort> =>
	orgId
		? requestWithAbort(urlGetPermissions({ ...props, orgId }), {
				method: 'GET',
			})
		: [() => Promise.resolve(), new AbortControllerFacade()];
